<template> 
<div class="page-content header-clear-small">
	<div style="height:100px"></div> 
	<div class="card card-style login">
        <div v-if="sent == 1" class="content mb-0 text-center">
            <h4 class="text-uppercase font-600 mb-2 font-14">Mot de passe oublié ?</h4>
            <div class="divider bg-orange-dark-sdw mb-3"></div>
            
			<p class="font-16 mb-3">
                Email de réinitialisation de mot de passe envoyé avec succès !
            </p>	  
			<a href="/" class="btn btn-full btn-m shadow-l rounded-s font-500 bg-orange-dark-sdw mt-4 mb-4 color-white nice-btn">Continuer</a>
		</div>  	
        <div v-else class="content mb-0 text-center">
            <h4 class="text-uppercase font-600 mb-2 font-14">Mot de passe oublié ?</h4>
            <div class="divider bg-orange-dark-sdw mb-3"></div>
            
			<p class="font-16 mb-3">
                Veuillez saisir les informations ci-dessous. 
            </p>
			<div class="input-style has-icon input-style-1 input-required">
				<i class="input-icon fa fa-at"></i>
				<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Email" :class="{'is-invalid': errors.forgotemail}" v-model="forgotemail" required>
				<div class="invalid-feedback" v-if="errors.forgotemail">{{errors.forgotemail[0]}}</div>
			</div>	  
			<button type="button" class="btn btn-full btn-m shadow-l rounded-s font-500 bg-orange-dark-sdw mt-4 mb-4 color-white nice-btn" @click.prevent="forgot">Envoyer</button>
		</div>	
    </div>
</div>
</template>
<script>
import AppConstants from '@/config'
export default {
	name: 'Forgot',
  data() {
    return{ 
		forgotemail:'',
		errors:{},
		sent:0
	}
  }, 
  components: {},
  beforeDestroy () {
  },
  created () {
	
  }, 
  mounted() {
  }, 
  methods: {
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	forgot(){
		var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"anonymous-forgotemail"
		if((token != "") && (id != "") && (this.forgotemail != "")) {
                    var apiData = { 
					"forgotemail": this.forgotemail,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
							this.sent = 1
					}
		})	
		} 	
			
	}
}	
}
</script>	
